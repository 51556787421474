import React from 'react';
import HomePageHero from './HomePageHero';
import HomePageFeature from './HomePageFeature';

export const HomePage: React.FC = () => {
    return (
        <>
            <HomePageHero />
            <HomePageFeature />
        </>
    );
}
