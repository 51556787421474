import React, { MouseEvent, ReactNode, startTransition } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * TransitionLink intercepts the normal <Link> click,
 * and wraps navigate in startTransition so that
 * lazy-loaded pages do not cause warnings about
 * "A component suspended while responding to synchronous input."
 */
interface TransitionLinkProps {
  to: string;
  children: ReactNode;
  className?: string;
}

export function TransitionLink({ to, children, className }: TransitionLinkProps) {
  const navigate = useNavigate();

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    startTransition(() => {
      navigate(to);
    });
  }

  return (
    <a href={to} onClick={handleClick} className={className}>
      {children}
    </a>
  );
}

export default TransitionLink;